<!--
  Display a single Token Type on the market, along with all of its associated listed Tokens.
  TODO: Currently unused and needs refactoring to function properly.
-->

<template>
  <div class="market-contract space-bottom">
    <TokenSingle
      ref="tokenSingle"
      :mode="mode"
      @loaded="loadingMedia = false"
    />
    <h4 class="listings-header">Market Listings</h4>
    <div v-if="!loadingMedia" class="table-wrapper">
      <v-data-table
        class="blkmnt token-table"
        :items="displayedTokens"
        :headers="computedHeaders"
        :sort-by="sortBy"
        :sort-desc="false"
        :custom-sort="customSort"
        @update:page="updatePage"
        @update:sort-by="sortCheck"
        :loading="loadingTokens"
        mobile-breakpoint="0"
        no-data-text="No tokens listed"
        :expanded.sync="expanded"
        item-key="tokenId"
        :footer-props="{
          itemsPerPageOptions: [numShown],
        }"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <transition name="slide-up" mode="out-in">
            <tr
              v-if="!isExpanded"
              key="closed"
              class="closed-row"
              @click="expand(!isExpanded)"
            >
              <td class="image-column" :style="`width: ${isMobile ? '66%' : '35%'};`">
                <div
                  class="token-image"
                  :style="`background-image:url(${tokenImagePath(item.nftContract, item.tokenType, 50, true)})`"
                />
                <div class="token-issuance">
                  #{{ item.tokenId ? formatIssuance(item.tokenId) : "?" }}
                  <span v-if="marketDataByType(marketCollection.id, item.tokenType)">
                    of {{ marketDataByType(marketCollection.id, item.tokenType).cap }}
                  </span>
                  <div v-if="isMobile">
                    {{
                      `
                        ${formatSecondaryType(item.tokenId, marketCollection) || 'Unknown'}
                        ${globalSettings && globalSettings.labels ? globalSettings.labels.typeSecondary : ''}
                      `
                    }}
                  </div>
                </div>
              </td>
              <td class="token-edition" v-if="headersMap.includes('tokenType')">
                {{
                  `
                    ${formatSecondaryType(item.tokenId, marketCollection) || 'Unknown'}
                    ${globalSettings && globalSettings.labels ? globalSettings.labels.typeSecondary : ''}
                  `
                }}
              </td>
              <td class="token-list-price">
                <CryptoFiat :crypto="item.price" />
              </td>
              <td class="details-column"  v-if="headersMap.includes('view_details')">
                <p class="text-action">Details</p>
              </td>
            </tr>
            <tr
              v-else
              key="open"
              class="expanded-row"
              @click="expand(!isExpanded)"
            >
              <td :colspan="4" style="cursor: arrow !important;">
                <div class="expanded-container">
                  <div class="collapse-button-wrapper">
                    <v-btn @click="expand(!isExpanded)" small icon>
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <div class="expanded-content">
                    <div
                      class="token-image large"
                      :style="`background-image:url(${tokenImagePath(item.nftContract, item.tokenType, 300, true)})`"
                    />
                    <div class="token-info">
                      <div>
                        <div class="title-grey">NUMBER</div>
                        <div class="title-large">
                          #{{ item.tokenId ? formatIssuance(item.tokenId) : "?" }}
                          <span v-if="marketDataByType(marketCollection.id, item.tokenType)">
                            of {{ marketDataByType(marketCollection.id, item.tokenType).cap }}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div class="title-grey">VARIANT</div>
                        <div class="title-info">
                          {{ formatSecondaryType(item.tokenId, marketCollection) || 'Unknown' }}
                          {{ globalSettings && globalSettings.labels ? globalSettings.labels.typeSecondary : '' }}
                        </div>
                      </div>
                      <div>
                        <div class="title-grey">OWNER</div>
                        <div class="title-info owner-wrapper">
                          <Avatar
                            :size="30"
                            :seed="item.seller"
                            :image="
                              item.image
                                ? dynamicLink(item.image, 'ar_1.0,c_fill,w_40')
                                : ''
                            "
                          />
                          <div class="owner-name">{{ item.sellerName }}</div>
                        </div>
                      </div>
                      <div>
                        <div class="title-grey">PROVENANCE</div>
                        <div
                          class="title-info text-action"
                          @click.stop.prevent="showTokenProvenanceModal(item)"
                        >
                          View Details
                        </div>
                      </div>
                    </div>
                    <div class="token-price-info">
                      <div class="token-price-row">
                        <div class="title-grey">ASK</div>
                        <div class="title-info">
                          <CryptoFiat :crypto="item.price" hideUsd/>
                        </div>
                      </div>
                      <div class="token-price-row">
                        <div class="title-grey">MARKET FEE</div>
                        <div class="title-info">
                          <CryptoFiat :crypto="marketFeeFormatted" hideUsd/>
                        </div>
                      </div>
                      <!-- <div class="token-price-row">
                        <div class="title-grey">GAS FEE</div>
                        <div class="title-info" >
                          <CryptoFiat :crypto="gasFeeFormatted" hideUsd/>
                        </div>
                      </div> -->
                      <div class="token-price-row">
                        <div class="title-grey">TOTAL PAYMENT</div>
                        <div class="title-info">
                          <CryptoFiat :crypto="calculatedTotal(item)" hideUsd/>
                        </div>
                      </div>

                      <div class="buy-now-wrapper">
                        <button
                          v-if="userProfile && userProfile.nearId == item.seller"
                          class="standard inverted"
                          @click.stop.prevent="viewInPortfolio(item)"
                        >
                          Portfolio
                        </button>
                        <button
                          v-else
                          class="standard inverted"
                          @click.stop.prevent="attemptPurchase(item)"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </transition>
        </template>
        <!-- don't remove below line -->
        <template v-slot:expanded-item="" />
      </v-data-table>
      <LoaderCover v-if="loading" transparentFull />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import BigNumber from 'bignumber.js'
import { utils } from 'near-api-js'

export default {
  name: 'MarketContract',
  data () {
    return {
      // 'contract' (shows all of a targe type) or 'collection' (shows all in a collection)
      mode: 'contract',
      expanded: [],
      numShown: 10,
      perLoad: 20,
      sortBy: 'tokenId',
      loading: true,
      loadingTokens: true,
      loadingMedia: true,
      loadedAll: false,
      primary: null,
      secondary: null,
      targetType: null,
      gasFee: '0.0',
      selectedEdition: '',
      headers: [
        {
          text: 'Issuance',
          value: 'tokenId'
        },
        {
          text: 'Variant',
          value: 'tokenType',
          hide: 'smAndDown'
        },
        {
          align: 'right',
          text: 'Ask',
          value: 'price'
        },
        {
          align: 'center',
          text: '',
          value: 'view_details',
          hide: 'smAndDown',
          sortable: false
        }
      ],
      headersMap: [],
      tokens: []
    }
  },

  computed: {
    ...mapGetters([
      'targetTypeData',
      'userProfile',
      'marketData',
      'marketDataByType',
      'marketDataByTypePrimary',
      'globalSettings',
      'blkdrpSettings',
      'marketCollection'
    ]),

    computedHeaders () {
      const computed = this.headers.filter((h) => !this.$vuetify.breakpoint[h.hide])
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.headersMap = computed.map(v => v.value)
      return computed
    },

    displayedTokens () {
      if (this.mode === 'collection-primary') {
        const formatted = []
        this.tokens.forEach((token) => {
          const primary = token.tokenType.split('.')[0]
          if (primary === this.$route.params.primary) {
            formatted.push(token)
          }
        })
        return formatted
      } else if (!this.loadingTokens) {
        if (this.selectedEdition !== '') {
          return this.tokens.filter((token) => this.formatSecondaryType(token.tokenId, this.marketCollection) === this.selectedEdition)
        }
        return this.tokens
      } else {
        return []
      }
    },
    marketFeeFormatted () {
      return utils.format.parseNearAmount(this.blkdrpSettings?.marketFee || '0')
    },
    gasFeeFormatted () {
      return utils.format.parseNearAmount(this.gasFee)
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },

  async created () {
    this.initData()
    this.targetTokenCheck()

    this.$root.$on('changeTokenView', (path) => {
      this.navigateToNewToken(path)
    })
  },

  beforeDestroy () {
    // Remove the listener, otherwise it will be triggered multiple times upon returning.
    this.$root.$off('changeTokenView')
  },

  methods: {
    ...mapActions(['initiatePurchase', 'getUserByNearId', 'getTargetTypeData']),
    ...mapMutations(['setTargetToken', 'setShowingTokenDetails', 'setShowingTokenProvenance']),

    /**
     * Check to see if there's a query in the URL for a specific token.
     * If so, load target sale data and display Token Detail modal.
     */
    async targetTokenCheck () {
      const targetTokenId = this.$route.query.token
      if (targetTokenId) {
        const settings = {
          marketContract: this.globalSettings
            ? this.globalSettings.marketContract
            : '',
          tokenContract: this.$route.params.collection,
          tokenId: targetTokenId
        }
        try {
          const res = await this.fbCall('getSaleData', settings)
          const saleData = res.data
          console.log('Sale data: ', saleData)
          this.setTargetToken(saleData)
          this.setShowingTokenDetails(true)
        } catch (err) {
          console.log(err)
          this.addNotification('Error getting market data.', 'error')
        }
      }
    },

    /**
     * Get target type data based on URL params.
     * Called when view is loaded and when another type is chosen.
     */
    async initData () {
      // Get information about target from URL
      this.primary = this.$route.params.primary
      this.secondary = this.$route.params.secondary || ''
      this.loadingTokens = true

      // If the user is requesting all of a target type, data retrieval methods change
      if (this.primary === 'all' && this.secondary === 'all') {
        this.mode = 'collection'
      } else if (this.secondary === 'all') {
        this.mode = 'collection-primary'
      } else {
        this.mode = 'contract'
        if (this.secondary !== '-') {
          this.targetType = this.primary + '.' + this.secondary
        } else {
          this.targetType = this.primary
        }
        try {
          await this.getTargetTypeData({
            type: this.targetType,
            contract: this.$route.params.collection
          })
        } catch (err) {
          this.$router.push(`/market/${this.$route.params.collection}`)
          this.addNotification(err.message, 'error', true)
        }
      }
      const tokens = await this.loadTokens()
      if (tokens) this.tokens = await this.formatTokens(tokens)

      this.loading = false
      this.loadingTokens = false
    },

    /**
     * Called when the user selects a new token type.
     * Updates path and manually reloads associated data. Also initializes the TokenSingle component.
     * @param {string} path - the new token path
     */
    async navigateToNewToken (path) {
      if (this.$route.fullPath === path) return
      await this.$router.push({ path: path })
      this.initData()
      this.$refs.tokenSingle.init()
    },

    /**
     * Sorting callback for the v-data-table component
     */
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'tokenId') {
          const iA = parseInt(this.formatIssuance(a.tokenId))
          const iB = parseInt(this.formatIssuance(b.tokenId))
          if (isDesc[0]) {
            return iB - iA
          } else {
            return iA - iB
          }
        } else if (index[0] === 'price') {
          // Prices need to use BigNumber comparison or will otherwise be sorted as strings.
          const bnA = new BigNumber(a.price)
          const bnB = new BigNumber(b.price)
          if (!isDesc[0]) {
            return bnA.comparedTo(bnB)
          } else {
            return bnB.comparedTo(bnA)
          }
        } else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase())
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase())
            }
          }
        }
      })
      return items
    },

    /**
     * Load tokens according to target type.
     * In "collection" mode, all listed tokens of the collection are called.
     * @param {number} os - the query offset (tokens are loaded in chunks)
     */
    async loadTokens (os = 0) {
      this.loading = true
      if (this.mode === 'collection' || this.mode === 'collection-primary') {
        const res = await this.fbCall('getAllListedTokens', {
          marketContract: this.globalSettings
            ? this.globalSettings.marketContract
            : '',
          tokenContract: this.$route.params.collection,
          limit: this.perLoad,
          offset: os
        })
        return res.data
      } else {
        const res = await this.fbCall('getAllListedByType', {
          marketContract: this.globalSettings
            ? this.globalSettings.marketContract
            : '',
          type: this.targetType,
          limit: this.perLoad,
          offset: os
        })
        return res.data
      }
    },

    /**
     * Loads more tokens on demand as the user pages through the table.
     */
    async updatePage (page) {
      if (this.loadedAll) return
      console.log(`Page ${page}. Num tokens: ${this.tokens.length}`)
      if ((page - 1) * this.perLoad >= this.tokens.length) {
        const newTokens = await this.loadTokens((page - 1) * this.perLoad)
        if (newTokens.length) {
          const formatted = await this.formatTokens(newTokens)
          this.tokens = this.tokens.concat(formatted)
        } else {
          this.loadedAll = true
        }
        this.loading = false
      }
    },

    sortCheck (update) {
      this.sortBy = update || 'tokenId'
    },

    /**
     * If there's a market fee, add that to the total price here.
     */
    calculatedTotal (item) {
      const bnPrice = new BigNumber(item.price)
      const bnFee = new BigNumber(this.marketFeeFormatted)
      const priceWithFee = bnPrice.plus(bnFee)
      return priceWithFee.toFixed()
    },

    /**
     * Formats the retrieved token objects for v-data-table columns.
     */
    async formatTokens (tokens) {
      const formatted = []
      for (const token of tokens) {
        const data = await this.getUserByNearId(token.seller)
        const tCopy = token
        tCopy.image = data.image
        tCopy.sellerName = data.name || token.seller
        tCopy.price =
          token.conditions && token.conditions[0]
            ? token.conditions[0].price
            : '0'
        formatted.push(tCopy)
      }
      return formatted
    },

    /**
     * Format purchasing data object an initialize the transaction.
     */
    attemptPurchase (target) {
      const purchaseFormatted = {
        chainId: process.env.VUE_APP_NEAR_NETWORK,
        marketContract: this.globalSettings
          ? this.globalSettings.marketContract
          : '',
        tokenContract: target.nftContract,
        tokenId: target.tokenId,
        buyer: this.userProfile ? this.userProfile.nearId : '',
        prices: target.conditions
      }
      this.setTargetToken(target)
      this.initiatePurchase(purchaseFormatted)
    },

    viewInPortfolio (target) {
      this.$router.push(`/portfolio/${target.nftContract}/${target.tokenId}`)
    },

    showTokenProvenanceModal (item) {
      this.setShowingTokenProvenance({ show: true, payload: { tokenId: item.tokenId, contractId: item.nftContract } })
    }
  }
}
</script>

<style lang="scss">
.listings-header {
  @include media($bp-tablet) {
    text-align: center;
  }
}

.table-wrapper {
  position: relative;
}

.token-table {
  .arrow-cursor {
    cursor: arrow !important;
  }

  .token-issuance {
    display: inline-block;
    vertical-align: middle;
    font-size: $font-size-ml;
    font-weight: bold;
    font-family: $font-title;
    color: white;

    > div {
      font-size: $font-size-s;
      font-weight: normal;
    }

    @include media($bp-phone-l) {
      font-size: $font-size-m;
    }
  }

  .token-list-price {
    text-align: right;

    @include media($bp-phone-l) {
      padding-right: $space-ss !important;
      min-width: $space-xxl;
    }
  }

  .token-image {
    @include bg-cover-center;
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    margin-right: $space-s;

    &.large {
      width: 280px;
      height: 280px;
      margin-right: $space-l;

      @include media($bp-desktop-s) {
        width: 140px;
        height: 140px;
      }

      @include media($bp-tablet) {
        margin-right: $space-ss;
      }
    }
  }
}

.text-action {
  text-decoration: underline;
}

.buy-now-wrapper {
  margin-top: 24px;
  text-align: right;

  button {
    width: 100%;
    @include media($bp-phone-l) {
      min-width: 100px;
    }
  }
}

.blkmnt .v-data-footer {
  @include media($bp-phone-l) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    .v-input {
      margin-left: 0;
      padding-left: 0;
    }

    .v-data-footer__select {
      display: none;
      font-size: $font-size-ms;
    }
  }
}
.expanded-container {
  position: relative;
  padding: $space-ll 0;

  @include media($bp-desktop-s) {
    padding-bottom: $space-ss;
  }

  .collapse-button-wrapper {
    position: absolute;
    top: $space-s;
    right: 0;
    display: flex;
    justify-content: flex-end;
  }

  .expanded-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $space-ml;

    @include media($bp-desktop-s) {
      padding: 0;
    }

    .token-info, .token-price-info {
      flex: 1;
    }

    .title-large {
      font-size: 24px;
      font-weight: bold;
      font-family: $font-title;
    }

    .title-info {
      font-size: 16px;
      font-family: $font-body;
    }

    .title-grey {
      font-size: 14px;
      color: $blk-grey-4;
    }

    .token-info {
      min-width: 140px;

      @include media($bp-tablet) {
        margin-top: 0;
        flex: 2;
      }
      .owner-wrapper {
        margin-top: $space-s;
      }
      > div:not(:first-child) {
        margin-top: $space-ms;
      }
    }

    .token-price-info {
      min-width: 200px;

      @include media($bp-tablet) {
        margin-top: $space-m;
        flex: 3;
      }
      > .token-price-row {
        display: flex;
        align-items: center;
        margin-bottom: $space-xs;

        div {
          flex: 1;
          padding: 8px 10px;
        }

        .title-info {
          flex: 1;
          text-align:right;
          border-bottom: 1px solid $blk-grey-4;
        }
      }
    }
  }
}
</style>
